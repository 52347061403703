@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,600&family=Sen&family=Vidaloka&display=swap');



/* html {
  scroll-behavior: smooth;

} */

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
 
  scroll-behavior: smooth;
  font-family: 'Montserrat','Noto Sans JP',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


