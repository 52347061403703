.TechStack{
    width: 100%;
    height: 100%;
}


.GridContainer{  
    height: 100%;
    transition: all 0.3s ease-in;
}

.GridContainer:hover{
    transform: scale(1.1);
    
    transition: all 0.15s ease-in;
}

.Title{
    text-align: center;
    margin-bottom: 3rem;
}



.CardMedia{
  
   width: 70px;
   height: 70px;
}
.cardmediaimage{
    display:flex;
    justify-content:center;
}




@media screen and (max-width: 800px){    
    .TechStack{
        width: 100%;
        height: 100%;
    }

    }

         