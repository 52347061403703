.Section{
position: inherit;
height: 100%;
overflow: hidden;
}
.main-section{
    width: 100%;
    height: 100%;
}

.container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.texts{
    white-space: nowrap;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    padding: 2rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13.1px);
    -webkit-backdrop-filter: blur(13.1px);
    border: 1px solid rgba(178, 183, 154, 0.25);

}



.texts h1-big{
    font-size: 50px;

}

.Github{
    width: 50px;
    height: 50px;

}


.Linkedin{
    width: 50px;
    height: 50px;
    margin-left: 1rem;
}

.texts h2{
    margin-top: 1rem;
}

.texts p{
    border-top: 2px solid grey;
    margin-top: 1rem;
    color: gray;
    width: 50%;
    font-size: 14px;
    text-transform: lowercase;
}

.text-button{
    margin-top: 1.2rem;
    padding: 14px 35px;
    cursor: pointer;
    background-color: rgb(59, 55, 55);
    color: #fff ;
    border:none;
    border-radius: 0.5rem;
    transition: all 0.8s ease-out;
}

.text-button:hover{
    background-color: rgb(255, 255, 255);
    transition: all 0.4s ease-in;
    color: rgb(0, 0, 0) ;
    border: solid 1px;
    
}

.image{
 
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 39% 100%, 0% 50%, 36% 0%);
    height: auto;
    background: linear-gradient(-45deg,  rgb(49, 66, 80), #767891, #41627a, #dcebe8);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


@media screen and (max-width: 960px){    
    .texts h1-big{
        font-size: 70px;
        margin-top: -150px;
    }

}





@media screen and (max-width: 600px){
   
    .texts{
        margin: 0 auto;
        width: 50%;
        text-transform: uppercase;
        justify-content: center;
        text-align: center;
    }
    
    .text-button{
        width: 100%;
        align-items: center;
    }
  
    .texts .h1-big{
        font-size: 30px;
        margin-top: 2rem;
    }
    
    .texts h2{
        margin-top: 0rem;
      
    }
    
    .texts p{
        border-top: 2px solid grey;
        text-align: center;
        color: rgb(120, 120, 129);
        font-size: 12px;
        text-transform: lowercase;
        width: 100%;
    }

    .container{
        align-items: center;
        
    }

   
}



@media screen and (max-width: 550px){
    

    .headshot{
        
        display: block;
        margin-left: auto;
        margin-right: auto;
       
    }

    .texts .h1-big{
        font-size: 20px;
        margin-top: 1rem;
    }
    
    .texts h2{
        margin-top: 0.5rem;
        font-size: 16px;
    }
    
    .texts h5{
        font-size: 10px;
      
    }

}



@media screen and (max-width:400px){
  

    .image{
        /* clip-path: polygon(20% 8%, 80% 8%, 100% 100%, 0% 100%); */
        /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
        clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);

        width: 100%;
       
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

