
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,600&family=Sen&family=Vidaloka&display=swap');
.header{    
    width: 100%;
    font-family: 'Noto Sans JP','Sen', sans-serif;
    font-weight: 200;
    position: absolute;
    height: 10%;
}


nav{
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1;
    top: 0;
}

.logo{
    flex:50%;
    margin-left: 1rem;
    padding: 1rem;
}

.logo h2{
    width:50%;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 25px;
    color: rgb(85, 82, 95);
    cursor: pointer;
   
    
}

.ul-items{
    position: relative;
    display: flex;
    flex:50%;
    list-style: none;
    align-items: right;
}

.ul-items li{
    margin-right: 3.5rem;
}

.ul-items li a{
    color: rgb(0, 0, 0);
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.1s ease-out;
} 

.ul-items li a:hover{
    cursor:pointer;
    color:var(--blueCard);
    font-size: 18px;
    transition: all 0.1s ease-in;
}

.hamburger-menu{
    margin-right: 2.5rem;
    display: flex;
    
    
}

.hamburger-menu .menu{
    font-size: 30px;
    color: rgb(73, 71, 71);
    cursor:pointer
}

.ClosingButton{
    color: rgb(255, 255, 255);
    cursor:pointer

}


.closing-menu-icon{
    text-align: center;

}

.slider{
    
    visibility: hidden;
    right: -100%;
    z-index: -1;
}

.slider.active{
    visibility: visible;
    position: absolute;
    display: grid; 
    width: 100%;
    height: 100vh;
    background-color: black;
     top: 0;
    right: 0;
    z-index: 1;
    transition: 0.5s ease-in-out;
  
}


.slider .slider-ul{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    list-style: none;
    text-align: center;
    align-items: center;
    gap: 4rem;
}


.slider-ul li{
    width: 400px;
    border: 2px solid #fff;
    padding: 45px;
}



.slider-ul a{
  text-decoration: none;
  font-size: 35px;
  color: #fff;
}


@media screen and (max-width: 1400px){



.slider .slider-ul{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    list-style: none;
    text-align: center;
    align-items: center;
    gap: 4rem;
}


.slider-ul li{
    width: 100%;
    border: 2px solid #fff;
    padding: 45px;
    cursor: pointer;
}

.slider-ul a{
  text-decoration: none;
  font-size: 35px;
  color: #fff;
}


}


@media screen and (max-width: 640px){
    .slider{
        height: 130vh;
    }


    .slider .slider-ul{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        text-align: center;
        

    }
    
    
    .slider-ul li{
        width: 100%;
        border: 2px solid #fff;
        padding: 45px;
       
    }
    
    .slider-ul a{
      text-decoration: none;
      font-size: 35px;
      color: #fff;
    }
  

    }


    @media screen and (max-width: 600px){
        .ul-items{
            visibility: hidden;
            }

        .hamburger-menu{
            visibility:visible;
        }


        
        .slider .slider-ul{
            width: 100%;
            display: table-column;
            grid-template-columns: repeat(2,1fr);
            list-style: none;
            gap: 2rem;
            
        }
        
        
     .Menuitem{
            width: 100%;
            border: 5px solid #fff;
            padding: 20px;
        
            align-items: center;
        }
        
        .slider-ul a{
          text-decoration: none;
          font-size: 30px;
          color: #fff;
        }
        

        }

