.Second{
    width: 100vw;
    height: 100vh;
    
} 
.section-2{
    
    
}

.about-me{
    display: flex;
    justify-content: center;
    height: 100%;
}

.container-2{
    display: flex;
    align-items: center;
    padding: 70px;
    justify-content: space-between;
}

.image-cont img{
    width: 400px;
    border: 1px solid rgb(0, 0, 0);
}


.text-3{
    font-size: 20px;
    line-height: 45px;
    border: 0.5px solid rgb(0, 0, 0);
    padding: 40px;
    margin-left: 40px;
}


.text-3 p {
    border-top: 1px solid rgb(0, 0, 0) ;
    
}



@media screen and (max-width: 600px){

    
    .image-cont img{
        width: 324px;
        height: 550px;
        border: 1px solid rgb(0, 0, 0);
    }
    
    .text-3 p {
        border-top: 1px solid rgb(0, 0, 0) ;
        
    }
    .text-3 {
        border-top: 1px solid rgb(0, 0, 0) ;
        margin-top: 5rem;
        margin-right: 3rem; 
    }
    

    .container-2{
        display: block;
        align-items: center;
        padding: 40px;
        justify-content: space-between;
    
    }

}




@media screen and (max-width: 400px){
 
    .image-cont img{
        width: 285px;
        height: 550px;
        border: 3px solid rgb(0, 0, 0);
    }
    
    .text-3 p {
        border-top: 2px solid rgb(0, 0, 0) ;
        
    }
    .text-3 {
        border-top: 2px solid rgb(0, 0, 0) ;
        margin-top: 5rem;
        margin-right: 3rem; 
    }
    

    .container-2{
        display: block;
        align-items: center;
        padding: 40px;
        justify-content: space-between;
    
    }

}