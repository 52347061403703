@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

Projects {
  margin: 0;
  width: auto;
  
  scroll-behavior: smooth;
  font-family: 'Montserrat';
}


/* .Projects{
    padding: 2rem 0;
}

.projects__allItems {
    display: flex;
    gap: 3rem;
    margin-top: 0rem;
  }
  .swiper-container {
    padding-top: 2rem;
    max-width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    
    height: 50px;
    width: 50px;
    background: transparent;
    z-index: 10;
    right: 60px;
    left: auto;
    top: 0;
    transform: translateY(50%);
    color: black;
    border-radius: 8px;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 2rem;
  }
  @media only screen and (max-width: 1400px) {
    .projects__allItems {
      flex-direction: column;
      max-width: 100%;
      
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom:4rem;
      gap: 4rem;
    }
    .projectItem__img {
        width: 100%;
        align-items:center;
        
      }
      .projectItem__img img {
        border: 3px solid black;
        border-radius:2px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        
      }
    
  } */
